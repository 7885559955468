import React from 'react'

export default function Footer() {
  return (
    <div className='footer'>
        <div className="container">
            Gokul Krishna E | gokul44krishna@gmail.com
        </div>
    </div>
  )
}
